import { format } from "date-fns";
import { Override } from "../types";
import { dateToStr } from "../utils/dates";
import {
  AssistType,
  DailyHabit as HabitDto,
  Event as EventDto,
  PlannerActionIntermediateResult,
  Task as TaskDto,
  TaskOrHabit as TaskOrHabitDto,
} from "./client";
import { dtoToEvent, Event } from "./Events";
import { dtoToHabit, Habit } from "./Habits";
import { dtoToTask, Task } from "./Tasks";
import { NotificationKeyStatus, TransformDomain } from "./types";

export type TaskOrHabit = Override<
  TaskOrHabitDto,
  {
    title: string | null;
    location?: string | null;
  }
>;

export const isTask = (taskOrHabit: TaskOrHabit): taskOrHabit is TaskDto => !!taskOrHabit && taskOrHabit.type === AssistType.TASK;
export const isHabit = (taskOrHabit: TaskOrHabit): taskOrHabit is HabitDto =>
  !!taskOrHabit && taskOrHabit.type !== AssistType.TASK;

export type PlannerActionResult = Override<
  PlannerActionIntermediateResult,
  {
    events: Event[];
    task?: Task;
    habit?: Habit;
  }
>;

export type PlannerActionQuery = {
  minutes?: number;
  eventId?: string;
  end?: Date;
  date?: Date;
  taskIndex?: number | null;
  enabled?: boolean;
};

export const fromDto = (dto: PlannerActionIntermediateResult): PlannerActionResult => {
  return {
    ...dto,
    events: dto.events.map((event: EventDto) => dtoToEvent(event)),
    task: isTask(dto.taskOrHabit as TaskOrHabit) ? dtoToTask(dto.taskOrHabit as TaskDto) : undefined,
    habit: isHabit(dto.taskOrHabit as TaskOrHabit) ? dtoToHabit(dto.taskOrHabit as HabitDto) : undefined,
  };
};

export class PlannerDomain extends TransformDomain<PlannerActionResult, PlannerActionIntermediateResult> {
  resource = "Planner";
  cacheKey = "planner";

  public deserialize = fromDto;

  // handleResult = (result: PlannerActionResult | null): PlannerActionResult | null => {
  private handleResult =
    (notificationKey?: string) =>
    (res: PlannerActionIntermediateResult): PlannerActionIntermediateResult => {
      if (!!notificationKey) this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);

      const result = this.deserialize(res);
      if (!!result?.task) this.client.tasks.upsert(result.task);
      if (!!result?.habit) this.client.habits.upsert(result.habit);
      if (!!result?.events) this.client.events.upsert(result.events);

      return res;
    };

  handleError = (reason, message: string, notificationKey: string) => {
    console.warn("Request failed", message);
    this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);

    throw reason;
  };

  setupNotification = (id: number | string): string => {
    const notificationKey = this.generateUid("planner", id);
    this.expectChange(notificationKey, id, {}, true);

    return notificationKey;
  };

  /**
   * Event Actions
   */
  moveEvent = this.manageErrors(
    this.deserializeResponse((eventId: string, start: string, end: string) => {
      const notificationKey = this.setupNotification(eventId);

      return this.api.planner
        .moveEvent(eventId, { start, end, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not move event", notificationKey));
    })
  );

  /**
   * Task Actions
   */

  // Extends the time on an in-flight/active instance
  extendTask = this.manageErrors(
    this.deserializeResponse((id: number, minutes?: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .extendTask(id, { minutes, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not extend task", notificationKey));
    })
  );

  // Adds time to the task policy
  addTime = this.manageErrors(
    this.deserializeResponse((id: number, minutes?: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .addTime(id, { minutes, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not extend task", notificationKey));
    })
  );

  logWork = this.manageErrors(
    this.deserializeResponse((id: number, query?: Pick<PlannerActionQuery, "minutes" | "end">) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .logWork(id, { minutes: query?.minutes, end: dateToStr(query?.end), notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not log work for task", notificationKey));
    })
  );

  restartTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .restartTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not restart task", notificationKey));
    })
  );

  snoozeTask = this.manageErrors(
    this.deserializeResponse((id: number, query?: Pick<PlannerActionQuery, "minutes" | "eventId">) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .snoozeTask(id, { minutes: query?.minutes, eventId: query?.eventId, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not snooze task", notificationKey));
    })
  );

  pushTask = this.manageErrors(
    this.deserializeResponse((id: number, date?: Date) => {
      const notificationKey = this.setupNotification(id);
      const dateStr = !!date ? format(date, "yyyy-MM-dd") : undefined;

      return this.api.planner
        .pushTask(id, { date: dateStr, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not pull task", notificationKey));
    })
  );

  pullTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .pullTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not pull task", notificationKey));
    })
  );

  startTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .startTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not start task now", notificationKey));
    })
  );

  stopTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .stopTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not stop task", notificationKey));
    })
  );

  doneTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .doneTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not mark task as done", notificationKey));
    })
  );

  incompleteTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .incompleteTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not mark task incomplete", notificationKey));
    })
  );

  unarchiveTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .unarchiveTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not unarchive task", notificationKey));
    })
  );

  deleteTaskInstance = this.manageErrors(
    this.deserializeResponse((id: number, index: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .deleteTask(id, index, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not delete task instance", notificationKey));
    })
  );

  deleteTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .deleteTaskPolicy(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not delete task", notificationKey));
    })
  );

  /**
   * Habit Actions
   */

  extendHabit = this.manageErrors(
    this.deserializeResponse((id: number, query?: PlannerActionQuery) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .extendHabit(id, { minutes: query?.minutes, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not extend habit", notificationKey));
    })
  );

  restartHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .restartHabit(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not restart habit", notificationKey));
    })
  );

  // TODO (SS): Remove default once not required on backend
  snoozeHabit = this.manageErrors(
    this.deserializeResponse((id: number, query?: PlannerActionQuery) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .snoozeHabit(id, { minutes: query?.minutes, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not snooze habit", notificationKey));
    })
  );

  startHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .startHabit(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not start habit now", notificationKey));
    })
  );

  stopHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .stopHabit(id)
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not stop habit", notificationKey));
    })
  );

  doneHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .doneHabit(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not mark habit as done", notificationKey));
    })
  );

  incompleteHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .incompleteHabit(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) =>
          this.handleError(reason, "Request failed: Could not mark habit as incomplete", notificationKey)
        );
    })
  );

  deleteHabitInstance = this.manageErrors(
    this.deserializeResponse((id: number, date?: Date) => {
      const notificationKey = this.setupNotification(id);
      const dateStr = date ? format(date, "yyyy-MM-dd") : undefined;

      return this.api.planner
        .deleteHabitInstance(id, { date: dateStr, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) =>
          this.handleError(reason, "Request failed: Could not delete habit instance", notificationKey)
        );
    })
  );

  deleteHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .deleteHabitPolicy(id)
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not delete habit", notificationKey));
    })
  );

  toggleHabit = this.manageErrors(
    this.deserializeResponse((id: number, enable?: boolean) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .toggleHabit(id, { enable, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not delete habit", notificationKey));
    })
  );
}
